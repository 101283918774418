
import { FormController, validationRules } from '@icepanel/app-form'
import { CodeRepoType, ModelObject, ModelObjectLink, ModelObjectLinkPartial, Task, UrlLinkPartial } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import * as icons from '../helpers/icons'
import * as resolveLink from '../helpers/resolve-link'
import { CodeModule } from '../store'
import LinkSelectMenu from './link-select-menu.vue'

@Component({
  components: {
    LinkSelectMenu,
    Menu
  },
  name: 'CodeLinkMenu'
})
export default class extends Vue {
  codeModule = getModule(CodeModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly formRef!: any
  @Ref() readonly menuRef!: Menu
  @Ref() readonly contentRef!: HTMLElement

  @Prop() readonly activator?: () => HTMLElement | undefined
  @Prop() readonly disabled?: boolean | undefined
  @Prop() readonly object!: ModelObject
  @Prop() readonly linkId?: string

  fileIcons = icons.files
  linkIcons = icons.links

  formController = new FormController({
    initialModel: {
      customName: '',
      link: null as ModelObjectLinkPartial | null,
      type: 'github' as CodeRepoType | 'url' | null,
      url: ''
    },
    validationRules: {
      link: [
        ...validationRules.exists,
        ...validationRules.url.map(o => (v: ModelObjectLinkPartial | null) => o(v?.url)),
        v => (!!v && (v.type === 'url' || v.status !== 'invalid')) || 'You must fix the integration link'
      ],
      type: validationRules.exists,
      url: [
        ...validationRules.exists,
        ...validationRules.url
      ]
    }
  })

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get loading () {
    return this.codeModule.codeReposListStatus.idle || this.codeModule.codeReposListStatus.loadingInfo.initial === true || (this.codeModule.codeReposListStatus.success && this.codeModule.codeReposListStatus.successInfo.organizationId !== this.currentOrganization.id)
  }

  get extension () {
    if (this.formController.model.link && 'path' in this.formController.model.link) {
      return this.formController.model.link.path.split('.').pop()
    }
  }

  get types () {
    const tabs: { text: string, value: CodeRepoType | 'url', icon: string }[] = []
    if (this.codeModule.codeRepos.some(o => o.type === 'github')) {
      tabs.push({
        icon: '$fab-github',
        text: 'GitHub',
        value: 'github'
      })
    }
    if (this.codeModule.codeRepos.some(o => o.type === 'bitbucket')) {
      tabs.push({
        icon: '$fab-bitbucket',
        text: 'Bitbucket',
        value: 'bitbucket'
      })
    }
    if (this.codeModule.codeRepos.some(o => o.type === 'gitlab')) {
      tabs.push({
        icon: '$fab-gitlab',
        text: 'GitLab',
        value: 'gitlab'
      })
    }
    if (this.codeModule.codeRepos.some(o => o.type === 'azure-devops')) {
      tabs.push({
        icon: '$fab-microsoft',
        text: 'Azure DevOps',
        value: 'azure-devops'
      })
    }
    if (this.codeModule.codeRepos.some(o => o.type === 'bitbucket-server')) {
      tabs.push({
        icon: '$fab-bitbucket',
        text: 'Bitbucket server',
        value: 'bitbucket-server'
      })
    }
    tabs.push({
      icon: '$fas-link',
      text: 'Generic link',
      value: 'url'
    })
    return tabs
  }

  get typeIcon () {
    return this.types.find(o => o.value === this.formController.model.type)?.icon
  }

  get linkInvalid () {
    return this.formController.model.link && 'status' in this.formController.model.link && this.formController.model.link.status === 'invalid'
  }

  get originalLinkInvalid () {
    const link = !!this.linkId && this.object.links[this.linkId]
    return link && 'status' in link && link.status === 'invalid'
  }

  get showSubmit () {
    return !this.linkId || this.originalLinkInvalid
  }

  get title () {
    if (this.originalLinkInvalid) {
      return 'Fix link to reality'
    } else if (this.linkId) {
      return 'Edit reality link'
    } else {
      return 'New link to reality'
    }
  }

  get submitText () {
    if (this.originalLinkInvalid) {
      return 'Fix link'
    } else {
      return 'Add link'
    }
  }

  created () {
    this.formController.submitHandler = async model => {
      const link = model.link
      if (!link) {
        return
      }

      const links: Record<string, ModelObjectLinkPartial> = window.structuredClone(this.object.links)

      let newLinkId: string
      let newLink: ModelObjectLinkPartial
      if (link.type === 'url') {
        newLinkId = this.modelModule.urlLinkId(link)
        newLink = {
          ...link,
          customName: this.formController.model.customName,
          id: newLinkId
        }
      } else {
        newLinkId = this.modelModule.codeRepoLinkId(link)
        newLink = {
          ...link,
          customName: this.formController.model.customName,
          id: newLinkId
        }
      }

      const lastLink = this.linkId ? links[this.linkId] : undefined

      if (this.linkId && lastLink) {
        delete links[this.linkId]

        if (newLink.type === 'url') {
          links[newLinkId] = {
            ...lastLink,
            ...newLink
          }
        } else {
          links[newLinkId] = {
            ...lastLink,
            ...newLink,
            status: 'valid'
          }
        }
      } else {
        links[newLinkId] = newLink
      }

      const tasks: Task[] = []
      const revertTasks: Task[] = []

      if (this.currentDiagram?.status === 'draft') {
        revertTasks.push({
          id: this.currentDiagram.id,
          props: {
            tasksProposed: {
              $append: [{
                id: this.object.id,
                props: {
                  links: this.object.links
                },
                type: 'model-object-update'
              }]
            }
          },
          type: 'diagram-content-update'
        }, {
          route: this.$route,
          type: 'navigation'
        })

        const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
          tasksProposed: {
            $append: [{
              id: this.object.id,
              props: {
                links
              },
              type: 'model-object-update'
            }]
          }
        })
        this.diagramModule.setDiagramContentVersion(diagramContent)
        this.editorModule.addToTaskQueue({
          func: () => this.diagramModule.diagramContentUpdate({
            diagramId: diagramContent.id,
            landscapeId: this.currentLandscape.id,
            props: diagramContentUpdate,
            versionId: this.currentVersion.id
          })
        })

        tasks.push({
          id: diagramContent.id,
          props: diagramContentUpdate,
          type: 'diagram-content-update'
        }, {
          route: this.$route,
          type: 'navigation'
        })
      } else {
        revertTasks.push({
          id: this.object.id,
          props: {
            links: this.object.links
          },
          type: 'model-object-update'
        }, {
          route: this.$route,
          type: 'navigation'
        })

        const { object, objectUpdate } = this.modelModule.generateObjectCommit(this.object.id, { links })
        this.modelModule.setObjectVersion(object)
        this.editorModule.addToTaskQueue({
          func: () => this.modelModule.objectUpdate({
            landscapeId: this.currentLandscape.id,
            objectId: object.id,
            props: objectUpdate,
            versionId: this.currentVersion.id
          })
        })

        tasks.push({
          id: object.id,
          props: objectUpdate,
          type: 'model-object-update'
        }, {
          route: this.$route,
          type: 'navigation'
        })
      }

      this.editorModule.addTaskList({
        revertTasks,
        tasks
      })

      if (this.showSubmit) {
        this.menuRef.close()
      }
    }
  }

  open () {
    this.checkLink()
  }

  async opened () {
    if (this.loading) {
      await this.codeModule.codeReposList(this.currentOrganization.id)
      this.checkLink()
    }
  }

  updateDimensions () {
    this.menuRef?.updateDimensions()
  }

  checkLink () {
    const link = this.linkId ? this.object.links[this.linkId] : undefined
    if (link) {
      this.formController.setInitialModel({
        customName: link.customName || '',
        link,
        type: link.type === 'url' ? 'url' : resolveLink.type(link.type),
        url: link.url
      })
    } else {
      this.formController.setInitialModel({
        customName: '',
        link: null,
        type: this.types[0]?.value || null,
        url: ''
      })
    }

    this.formController.resetModel()
    this.formController.resetStatus()
  }

  autoSave () {
    if (!this.showSubmit) {
      this.formController.submit(this.formRef)
    }
  }

  updateModelUrl (url: string) {
    try {
      const { host, pathname } = new URL(url)
      const path = pathname.replace(/(^\/+|\/+$)/g, '')
      const name = host + (path ? `/${path}` : path)

      const link: UrlLinkPartial = {
        name,
        type: 'url',
        url
      }
      this.formController.model.url = url
      this.formController.model.link = {
        ...link,
        id: this.modelModule.urlLinkId(link)
      }
    } catch (err) {}
  }

  deleteLink () {
    const links: Record<string, ModelObjectLink> = window.structuredClone(this.object.links)
    if (this.linkId) {
      delete links[this.linkId]
    }

    const tasks: Task[] = []
    const revertTasks: Task[] = []

    if (this.currentDiagram?.status === 'draft') {
      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          tasksProposed: {
            $append: [{
              id: this.object.id,
              props: {
                links: this.object.links
              },
              type: 'model-object-update'
            }]
          }
        },
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        tasksProposed: {
          $append: [{
            id: this.object.id,
            props: {
              links
            },
            type: 'model-object-update'
          }]
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramContentUpdate({
          diagramId: diagramContent.id,
          landscapeId: this.currentLandscape.id,
          props: diagramContentUpdate,
          versionId: this.currentVersion.id
        })
      })

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })
    } else {
      revertTasks.push({
        id: this.object.id,
        props: {
          links: this.object.links
        },
        type: 'model-object-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { object, objectUpdate } = this.modelModule.generateObjectCommit(this.object.id, { links })
      this.modelModule.setObjectVersion(object)
      this.editorModule.addToTaskQueue({
        func: () => this.modelModule.objectUpdate({
          landscapeId: this.currentLandscape.id,
          objectId: object.id,
          props: objectUpdate,
          versionId: this.currentVersion.id
        })
      })

      tasks.push({
        id: object.id,
        props: objectUpdate,
        type: 'model-object-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })
    }

    this.editorModule.addTaskList({
      revertTasks,
      tasks
    })

    this.menuRef.close()
  }
}
