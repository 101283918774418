import * as analytics from '@icepanel/app-analytics'
import { CatalogProvider, TagColor } from '@icepanel/platform-api-client'

export const catalogTechnologyColor: analytics.Property<TagColor> = {
  displayName: 'Catalog tag color',
  examples: ['beaver', 'black', 'blue', 'dark-blue', 'green', 'grey', 'orange', 'pink', 'purple', 'red', 'white', 'yellow'],
  name: 'catalog_technology_color',
  required: true,
  type: 'string'
}
export const catalogTechnologyProvider: analytics.Property<CatalogProvider | null> = {
  displayName: 'Catalog technology provider',
  examples: ['aws', 'gcp', 'azure'],
  name: 'catalog_technology_provider',
  required: true,
  type: 'string'
}
export const catalogTechnologyName: analytics.Property<string> = {
  displayName: 'Catalog technology name',
  name: 'catalog_technology_name',
  required: true,
  type: 'string'
}
export const catalogTechnologyNameShort: analytics.Property<string | undefined> = {
  displayName: 'Catalog technology name short',
  name: 'catalog_technology_name_short',
  required: false,
  type: 'string'
}
