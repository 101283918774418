
import {
  CodeRepo,
  CodeRepoLink,
  CodeRepoLinkAzureDevops,
  CodeRepoLinkAzureDevopsBranch,
  CodeRepoLinkAzureDevopsFile,
  CodeRepoLinkAzureDevopsFolder,
  CodeRepoLinkAzureDevopsRepo,
  CodeRepoLinkBitbucket,
  CodeRepoLinkBitbucketBranch,
  CodeRepoLinkBitbucketFile,
  CodeRepoLinkBitbucketFolder,
  CodeRepoLinkBitbucketRepo,
  CodeRepoLinkBitbucketServer,
  CodeRepoLinkBitbucketServerBranch,
  CodeRepoLinkBitbucketServerFile,
  CodeRepoLinkBitbucketServerFolder,
  CodeRepoLinkBitbucketServerRepo,
  CodeRepoLinkGithub,
  CodeRepoLinkGithubBranch,
  CodeRepoLinkGithubFile,
  CodeRepoLinkGithubFolder,
  CodeRepoLinkGithubRepo,
  CodeRepoLinkGitlab,
  CodeRepoLinkGitlabBranch,
  CodeRepoLinkGitlabFile,
  CodeRepoLinkGitlabFolder,
  CodeRepoLinkGitlabRepo,
  CodeRepoLinkPartial,
  CodeRepoType
} from '@icepanel/platform-api-client'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import openExternalLink from '@/helpers/open-external-link'
import { AlertModule } from '@/modules/alert/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import * as modelAnalytics from '@/modules/model/helpers/analytics'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'
import * as icons from '../helpers/icons'
import * as resolveLink from '../helpers/resolve-link'
import { CodeModule } from '../store'

interface IItem {
  children?: IItem[]
  codeRepoId: string
  codeRepoRefId?: string
  extension?: string
  id: string
  link: CodeRepoLinkPartial
  name: string
}

@Component({
  components: {
    Menu
  },
  name: 'CodeLinkSelectMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  codeModule = getModule(CodeModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly disabled?: boolean
  @Prop() readonly type?: CodeRepoType
  @Prop() readonly link?: CodeRepoLink

  @Ref() readonly menuRef!: Menu
  @Ref() readonly treeScrollRef?: HTMLElement

  loading = true

  searching = false
  searchTerm = ''
  searchTermModel = ''

  tree: IItem[] = []
  visible: IItem[] = []
  active: IItem[] = []
  items: IItem[] = []

  fileIcons = icons.files
  linkIcons = icons.links

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get codeRepoTreesSyncStatus () {
    return this.codeModule.codeRepoTreesSyncStatus
  }

  get codeRepos () {
    return this.codeModule.codeRepos.filter(o => !this.type || o.type === this.type)
  }

  get types (): { text: string, value: CodeRepoType, icon: string }[] {
    return [
      {
        icon: '$fab-github',
        text: 'GitHub',
        value: 'github'
      },
      {
        icon: '$fab-gitlab',
        text: 'GitLab',
        value: 'gitlab'
      },
      {
        icon: '$fab-bitbucket',
        text: 'Bitbucket',
        value: 'bitbucket'
      },
      {
        icon: '$fab-microsoft',
        text: 'Azure DevOps',
        value: 'azure-devops'
      },
      {
        icon: '$fab-bitbucket',
        text: 'Bitbucket server',
        value: 'bitbucket-server'
      }
    ]
  }

  get itemsFiltered () {
    if (this.searchTerm.startsWith('https://')) {
      return this.items
    } else {
      return this.items.filter(o => o.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
    }
  }

  get itemsTooLong () {
    return this.itemsFiltered.length > 500
  }

  get typeTitle () {
    return (item: CodeRepoLink) => {
      return this.types.find(o => o.value === resolveLink.type(item.type))?.text
    }
  }

  get itemId () {
    return (link: CodeRepoLinkAzureDevops | CodeRepoLinkBitbucket | CodeRepoLinkBitbucketServer | CodeRepoLinkGithub | CodeRepoLinkGitlab) => {
      switch (link.type) {
        case 'azure-devops-repo': return `${link.accountName}/${link.projectId}/${link.repositoryId}`
        case 'azure-devops-branch': return `${link.accountName}/${link.projectId}/${link.repositoryId}/${link.branchName}`
        case 'azure-devops-folder': return `${link.accountName}/${link.projectId}/${link.repositoryId}/${link.branchName}/${link.path}`
        case 'azure-devops-file': return `${link.accountName}/${link.projectId}/${link.repositoryId}/${link.branchName}/${link.path}`

        case 'bitbucket-repo': return `${link.workspaceId}/${link.projectId}/${link.repositoryId}`
        case 'bitbucket-branch': return `${link.workspaceId}/${link.projectId}/${link.repositoryId}/${link.branchName}`
        case 'bitbucket-folder': return `${link.workspaceId}/${link.projectId}/${link.repositoryId}/${link.branchName}/${link.path}`
        case 'bitbucket-file': return `${link.workspaceId}/${link.projectId}/${link.repositoryId}/${link.branchName}/${link.path}`

        case 'bitbucket-server-repo': return `${link.serverUrl}/${link.projectId}/${link.repositoryId}`
        case 'bitbucket-server-branch': return `${link.serverUrl}/${link.projectId}/${link.repositoryId}/${link.branchName}`
        case 'bitbucket-server-folder': return `${link.serverUrl}/${link.projectId}/${link.repositoryId}/${link.branchName}/${link.path}`
        case 'bitbucket-server-file': return `${link.serverUrl}/${link.projectId}/${link.repositoryId}/${link.branchName}/${link.path}`

        case 'github-repo': return `${link.ownerId}/${link.repositoryId}`
        case 'github-branch': return `${link.ownerId}/${link.repositoryId}/${link.branchName}`
        case 'github-folder': return `${link.ownerId}/${link.repositoryId}/${link.branchName}/${link.path}`
        case 'github-file': return `${link.ownerId}/${link.repositoryId}/${link.branchName}/${link.path}`

        case 'gitlab-repo': return `${link.groupId}/${link.projectId}`
        case 'gitlab-branch': return `${link.groupId}/${link.projectId}/${link.branchName}`
        case 'gitlab-folder': return `${link.groupId}/${link.projectId}/${link.branchName}/${link.path}`
        case 'gitlab-file': return `${link.groupId}/${link.projectId}/${link.branchName}/${link.path}`
      }
    }
  }

  get codeRepoLinkAzureDevops () {
    return (codeRepo: CodeRepo): CodeRepoLinkAzureDevopsRepo & Pick<CodeRepoLink, 'id' | 'name' | 'url'> => {
      if (codeRepo.azureDevopsAccountName && codeRepo.azureDevopsProjectId && codeRepo.azureDevopsRepositoryId) {
        const link: CodeRepoLinkAzureDevopsRepo = {
          accountName: codeRepo.azureDevopsAccountName,
          projectId: codeRepo.azureDevopsProjectId,
          repositoryId: codeRepo.azureDevopsRepositoryId,
          type: 'azure-devops-repo'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else {
        throw new Error('Code repo integration not found')
      }
    }
  }

  get codeRepoLinkBitbucket () {
    return (codeRepo: CodeRepo): CodeRepoLinkBitbucketRepo & Pick<CodeRepoLink, 'id' | 'name' | 'url'> => {
      if (codeRepo.bitbucketWorkspaceId && codeRepo.bitbucketProjectId && codeRepo.bitbucketRepositoryId) {
        const link: CodeRepoLinkBitbucketRepo = {
          projectId: codeRepo.bitbucketProjectId,
          repositoryId: codeRepo.bitbucketRepositoryId,
          type: 'bitbucket-repo',
          workspaceId: codeRepo.bitbucketWorkspaceId
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else {
        throw new Error('Code repo integration not found')
      }
    }
  }

  get codeRepoLinkBitbucketServer () {
    return (codeRepo: CodeRepo): CodeRepoLinkBitbucketServerRepo & Pick<CodeRepoLink, 'id' | 'name' | 'url'> => {
      if (codeRepo.bitbucketServerUrl && codeRepo.bitbucketServerProjectId && codeRepo.bitbucketServerRepositoryId) {
        const link: CodeRepoLinkBitbucketServerRepo = {
          projectId: codeRepo.bitbucketServerProjectId,
          repositoryId: codeRepo.bitbucketServerRepositoryId,
          serverUrl: codeRepo.bitbucketServerUrl,
          type: 'bitbucket-server-repo'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else {
        throw new Error('Code repo integration not found')
      }
    }
  }

  get codeRepoLinkGithub () {
    return (codeRepo: CodeRepo): CodeRepoLinkGithubRepo & Pick<CodeRepoLink, 'id' | 'name' | 'url'> => {
      if (codeRepo.githubOwnerId && codeRepo.githubRepositoryId) {
        const link: CodeRepoLinkGithubRepo = {
          ownerId: codeRepo.githubOwnerId,
          repositoryId: codeRepo.githubRepositoryId,
          type: 'github-repo'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else {
        throw new Error('Code repo integration not found')
      }
    }
  }

  get codeRepoLinkGitlab () {
    return (codeRepo: CodeRepo): CodeRepoLinkGitlab & Pick<CodeRepoLink, 'id' | 'name' | 'url'> => {
      if (codeRepo.gitlabGroupId && codeRepo.gitlabProjectId) {
        const link: CodeRepoLinkGitlab = {
          groupId: codeRepo.gitlabGroupId,
          projectId: codeRepo.gitlabProjectId,
          type: 'gitlab-repo'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else {
        throw new Error('Code repo integration not found')
      }
    }
  }

  get codeRepoLink () {
    return (codeRepo: CodeRepo): (CodeRepoLinkAzureDevopsRepo | CodeRepoLinkBitbucket | CodeRepoLinkBitbucketServerRepo | CodeRepoLinkGithubRepo | CodeRepoLinkGitlabRepo) & Pick<CodeRepoLink, 'id' | 'name' | 'url'> => {
      if (codeRepo.azureDevopsAccountName && codeRepo.azureDevopsProjectId && codeRepo.azureDevopsRepositoryId) {
        const link: CodeRepoLinkAzureDevopsRepo & Pick<CodeRepoLink, 'id' | 'name' | 'url'> = {
          ...this.codeRepoLinkAzureDevops(codeRepo),
          type: 'azure-devops-repo'
        }
        return link
      } else if (codeRepo.bitbucketWorkspaceId && codeRepo.bitbucketProjectId && codeRepo.bitbucketRepositoryId) {
        const link: CodeRepoLinkBitbucketRepo & Pick<CodeRepoLink, 'id' | 'name' | 'url'> = {
          ...this.codeRepoLinkBitbucket(codeRepo),
          type: 'bitbucket-repo'
        }
        return link
      } else if (codeRepo.bitbucketServerUrl && codeRepo.bitbucketServerProjectId && codeRepo.bitbucketServerRepositoryId) {
        const link: CodeRepoLinkBitbucketServerRepo & Pick<CodeRepoLink, 'id' | 'name' | 'url'> = {
          ...this.codeRepoLinkBitbucketServer(codeRepo),
          type: 'bitbucket-server-repo'
        }
        return link
      } else if (codeRepo.githubOwnerId && codeRepo.githubRepositoryId) {
        const link: CodeRepoLinkGithubRepo & Pick<CodeRepoLink, 'id' | 'name' | 'url'> = {
          ...this.codeRepoLinkGithub(codeRepo),
          type: 'github-repo'
        }
        return link
      } else if (codeRepo.gitlabGroupId && codeRepo.gitlabProjectId) {
        const link: CodeRepoLinkGitlabRepo & Pick<CodeRepoLink, 'id' | 'name' | 'url'> = {
          ...this.codeRepoLinkGitlab(codeRepo),
          type: 'gitlab-repo'
        }
        return link
      } else {
        throw new Error('Code repo integration not found')
      }
    }
  }

  get codeRepoLinkBranch () {
    return (codeRepo: CodeRepo, branchName: string): (CodeRepoLinkAzureDevopsBranch | CodeRepoLinkBitbucketBranch | CodeRepoLinkBitbucketServerBranch | CodeRepoLinkGithubBranch | CodeRepoLinkGitlabBranch) & Pick<CodeRepoLink, 'id' | 'name' | 'url'> => {
      if (codeRepo.azureDevopsAccountName && codeRepo.azureDevopsProjectId && codeRepo.azureDevopsRepositoryId) {
        const link: CodeRepoLinkAzureDevopsBranch = {
          ...this.codeRepoLinkAzureDevops(codeRepo),
          branchName,
          type: 'azure-devops-branch'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.bitbucketWorkspaceId && codeRepo.bitbucketProjectId && codeRepo.bitbucketRepositoryId) {
        const link: CodeRepoLinkBitbucketBranch = {
          ...this.codeRepoLinkBitbucket(codeRepo),
          branchName,
          type: 'bitbucket-branch'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.bitbucketServerUrl && codeRepo.bitbucketServerProjectId && codeRepo.bitbucketServerRepositoryId) {
        const link: CodeRepoLinkBitbucketServerBranch = {
          ...this.codeRepoLinkBitbucketServer(codeRepo),
          branchName,
          type: 'bitbucket-server-branch'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.githubOwnerId && codeRepo.githubRepositoryId) {
        const link: CodeRepoLinkGithubBranch = {
          ...this.codeRepoLinkGithub(codeRepo),
          branchName,
          type: 'github-branch'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.gitlabGroupId && codeRepo.gitlabProjectId) {
        const link: CodeRepoLinkGitlabBranch = {
          ...this.codeRepoLinkGitlab(codeRepo),
          branchName,
          type: 'gitlab-branch'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else {
        throw new Error('Code repo integration not found')
      }
    }
  }

  get codeRepoLinkFolder () {
    return (codeRepo: CodeRepo, branchName: string, path: string): (CodeRepoLinkAzureDevopsFolder | CodeRepoLinkBitbucketFolder | CodeRepoLinkBitbucketServerFolder | CodeRepoLinkGithubFolder | CodeRepoLinkGitlabFolder) & Pick<CodeRepoLink, 'id' | 'name' | 'url'> => {
      if (codeRepo.azureDevopsAccountName && codeRepo.azureDevopsProjectId && codeRepo.azureDevopsRepositoryId) {
        const link: CodeRepoLinkAzureDevopsFolder = {
          ...this.codeRepoLinkAzureDevops(codeRepo),
          branchName,
          path,
          type: 'azure-devops-folder'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.bitbucketWorkspaceId && codeRepo.bitbucketProjectId && codeRepo.bitbucketRepositoryId) {
        const link: CodeRepoLinkBitbucketFolder = {
          ...this.codeRepoLinkBitbucket(codeRepo),
          branchName,
          path,
          type: 'bitbucket-folder'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.bitbucketServerUrl && codeRepo.bitbucketServerProjectId && codeRepo.bitbucketServerRepositoryId) {
        const link: CodeRepoLinkBitbucketServerFolder = {
          ...this.codeRepoLinkBitbucketServer(codeRepo),
          branchName,
          path,
          type: 'bitbucket-server-folder'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.githubOwnerId && codeRepo.githubRepositoryId) {
        const link: CodeRepoLinkGithubFolder = {
          ...this.codeRepoLinkGithub(codeRepo),
          branchName,
          path,
          type: 'github-folder'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.gitlabGroupId && codeRepo.gitlabProjectId) {
        const link: CodeRepoLinkGitlabFolder = {
          ...this.codeRepoLinkGitlab(codeRepo),
          branchName,
          path,
          type: 'gitlab-folder'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else {
        throw new Error('Code repo integration not found')
      }
    }
  }

  get codeRepoLinkFile () {
    return (codeRepo: CodeRepo, branchName: string, path: string): (CodeRepoLinkAzureDevopsFile | CodeRepoLinkBitbucketFile | CodeRepoLinkBitbucketServerFile | CodeRepoLinkGithubFile | CodeRepoLinkGitlabFile) & Pick<CodeRepoLink, 'id' | 'name' | 'url'> => {
      if (codeRepo.azureDevopsAccountName && codeRepo.azureDevopsProjectId && codeRepo.azureDevopsRepositoryId) {
        const link: CodeRepoLinkAzureDevopsFile = {
          ...this.codeRepoLinkAzureDevops(codeRepo),
          branchName,
          path,
          type: 'azure-devops-file'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.bitbucketWorkspaceId && codeRepo.bitbucketProjectId && codeRepo.bitbucketRepositoryId) {
        const link: CodeRepoLinkBitbucketFile = {
          ...this.codeRepoLinkBitbucket(codeRepo),
          branchName,
          path,
          type: 'bitbucket-file'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.bitbucketServerUrl && codeRepo.bitbucketServerProjectId && codeRepo.bitbucketServerRepositoryId) {
        const link: CodeRepoLinkBitbucketServerFile = {
          ...this.codeRepoLinkBitbucketServer(codeRepo),
          branchName,
          path,
          type: 'bitbucket-server-file'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.githubOwnerId && codeRepo.githubRepositoryId) {
        const link: CodeRepoLinkGithubFile = {
          ...this.codeRepoLinkGithub(codeRepo),
          branchName,
          path,
          type: 'github-file'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else if (codeRepo.gitlabGroupId && codeRepo.gitlabProjectId) {
        const link: CodeRepoLinkGitlabFile = {
          ...this.codeRepoLinkGitlab(codeRepo),
          branchName,
          path,
          type: 'gitlab-file'
        }
        return {
          ...link,
          id: this.modelModule.codeRepoLinkId(link),
          name: resolveLink.name(codeRepo, link),
          url: resolveLink.url(codeRepo, link)
        }
      } else {
        throw new Error('Code repo integration not found')
      }
    }
  }

  @Watch('visible')
  onVisibleChanged () {
    this.menuRef.updateDimensions()
  }

  @Watch('itemsFiltered')
  onItemsFilteredChanged () {
    this.menuRef.updateDimensions()
  }

  @Watch('searchTerm')
  async onSearchTermChanged (searchTerm: string) {
    if (searchTerm.startsWith('https://')) {
      const path = resolveLink.urlComponents(searchTerm)
      if (path) {
        this.searching = true
        await this.openPathHierarchy(path)
        this.searching = false

        await this.$nextTick()
        this.showActiveItem()

        if (this.active[0]) {
          this.$emit('changed', this.active[0].link)
        }
      }
    } else {
      this.active = []

      if (this.itemsFiltered.length === 1) {
        this.visible = [this.itemsFiltered[0]]
      } else {
        this.visible = []
      }
    }
  }

  async open () {
    this.searchTerm = ''
    this.searchTermModel = ''
    this.visible = []
    this.active = []
    this.loading = true

    this.menuRef.updateDimensions()

    try {
      // show the UI before starting to chomp
      await new Promise(resolve => setTimeout(resolve, 200))

      await this.loadChildren()

      if (this.link) {
        const id = this.itemId(this.link)
        await this.openItemHierarchy(id, true)
      }
    } finally {
      this.loading = false
    }

    if (this.link) {
      await this.$nextTick()
      this.showActiveItem()
    }
  }

  opened () {
    analytics.codeLinkSelectMenu.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  setSearchTermDebounce = debounce(this.setSearchTerm.bind(this), 500, {
    leading: false,
    trailing: true
  })

  setSearchTerm (term: string) {
    this.searchTerm = term
  }

  findItem (codeRepoId: string, codeRepoRefId?: string, path?: string[]) {
    let curItem = this.items.find(o => o.codeRepoId === codeRepoId)

    if (codeRepoRefId) {
      const foundItem = curItem?.children?.find(o => o.codeRepoRefId === codeRepoRefId)
      if (foundItem) {
        curItem = foundItem
      } else {
        return null
      }
    }

    if (path) {
      for (const p of path) {
        const foundItem = curItem?.children?.find(o => o.name === p)
        if (foundItem) {
          curItem = foundItem
        } else {
          return null
        }
      }
    }

    return curItem || null
  }

  async loadChildren (item?: IItem) {
    try {
      const codeRepo = this.codeRepos.find(o => o.id === item?.codeRepoId)
      const branchName = item && 'branchName' in item.link ? item.link.branchName : undefined

      if (!item) {
        const loaded = this.codeModule.codeReposListStatus.successInfo.organizationId === this.currentOrganization.id
        if (!loaded || this.codeModule.codeReposListStatus.secondsAgo > 5 * 60) {
          await this.codeModule.codeReposList(this.currentOrganization.id)
        }
        this.items = this.codeRepos
          .map(o => {
            const codeRepoLink = this.codeRepoLink(o)
            return {
              children: [],
              codeRepoId: o.id,
              id: this.itemId(codeRepoLink),
              link: codeRepoLink,
              name: o.name
            }
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      } else if (codeRepo && item.link.type.endsWith('repo')) {
        const branchOrder = [
          'main',
          'master',
          'develop',
          'dev',
          'release'
        ]

        item.children = Object
          .values(codeRepo.refs)
          .filter(o => o.name === 'main' || o.name === 'master' || o.name === 'develop' || o.name === 'release' || o.name === 'dev')
          .map(o => ({
            children: [],
            codeRepoId: item.codeRepoId,
            codeRepoRefId: o.id,
            id: this.itemId(this.codeRepoLinkBranch(codeRepo, o.name)),
            link: this.codeRepoLinkBranch(codeRepo, o.name),
            name: o.name
          }))
          .sort((a, b) => {
            const aIndex = branchOrder.indexOf(a.name)
            const bIndex = branchOrder.indexOf(b.name)
            if (a.name === b.name) {
              return 0
            } else if (aIndex > -1 && bIndex > -1) {
              return aIndex > bIndex ? 1 : -1
            } else if (aIndex > -1) {
              return -1
            } else if (bIndex > -1) {
              return 1
            } else {
              return a.name.localeCompare(b.name)
            }
          })
      } else if (codeRepo && item.link.type.endsWith('branch') && item.codeRepoRefId && branchName) {
        try {
          await this.codeModule.codeRepoRefTreeFind({
            codeRepoId: item.codeRepoId,
            organizationId: this.currentOrganization.id,
            refId: item.codeRepoRefId
          })
        } catch (err: any) {
          // try syncing the code repo if the tree was not found
          if (err.status === 404) {
            await this.codeModule.codeRepoTreesSync({
              codeRepoId: item.codeRepoId,
              organizationId: this.currentOrganization.id
            })
            await this.codeModule.codeRepoRefTreeFind({
              codeRepoId: item.codeRepoId,
              organizationId: this.currentOrganization.id,
              refId: item.codeRepoRefId
            })
          } else {
            this.alertModule.pushAlert({
              color: 'error',
              message: err.body?.message || err.message
            })
          }
        }

        item.children = []

        this.codeModule.codeRepoRefTree.forEach(o => {
          const splitPath = o.path.split('/')
          const name = splitPath[splitPath.length - 1]
          const link = o.type === 'tree' ? this.codeRepoLinkFolder(codeRepo, branchName, o.path) : this.codeRepoLinkFile(codeRepo, branchName, o.path)
          const newItem: IItem = {
            codeRepoId: item.codeRepoId,
            codeRepoRefId: item.codeRepoRefId,
            id: this.itemId(link),
            link,
            name
          }
          if (o.type === 'blob') {
            newItem.extension = name.split('.').pop()
          }
          if (o.type === 'tree') {
            newItem.children = []
          }
          const parentItem = this.findItem(item.codeRepoId, item.codeRepoRefId, splitPath.slice(0, -1))
          parentItem?.children?.push(newItem)
        })
      }

      await this.$nextTick()

      this.menuRef?.updateDimensions()
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body?.message || err.message
      })
    }
  }

  async openItemHierarchy (id: string, activateItem = false, items = this.items) {
    await Promise.all(items.map(async item => {
      if (item.id === id && activateItem) {
        this.active = [item]
      } else if (id.startsWith(item.id) && !item.link.type.endsWith('file')) {
        await this.loadChildren(item)
        await this.openItemHierarchy(id, activateItem, item.children)
        this.visible.push(item)
      }
    }))
  }

  async openPathHierarchy (path: string, items = this.items) {
    // check every possible prefix combination against the list of items
    const pathComponents = path.split('/')
    for (let i = 0; i <= pathComponents.length; i++) {
      const pathSection = pathComponents.slice(0, i).join('/')
      const pathOtherSection = pathComponents.slice(i).join('/')

      // find item that matches the path section
      const matchedItems = items.filter(o => pathSection === o.name)
      for (const matchedItem of matchedItems) {
        await this.loadChildren(matchedItem)
        await this.openPathHierarchy(pathOtherSection, matchedItem.children)

        // expand each section, and select the final item
        if (!pathOtherSection) {
          this.active = [matchedItem]
        } else if (!matchedItem.link.type.endsWith('file')) {
          this.visible.push(matchedItem)
        }
      }
    }
  }

  async codeRepoTreesSync (codeRepoId: string) {
    await this.codeModule.codeRepoTreesSync({
      codeRepoId,
      organizationId: this.currentOrganization.id
    })
  }

  showActiveItem () {
    if (this.treeScrollRef) {
      this.$vuetify.goTo('.v-treeview-node--active', {
        container: this.treeScrollRef,
        duration: 0,
        offset: 140
      })
    }
  }

  openLink (item: IItem) {
    if (item.link.url) {
      openExternalLink(item.link.url)

      modelAnalytics.modelObjectLinkOpen.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelObjectLinkType: item.link.type,
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }
}
