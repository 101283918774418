
import { ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import LinkMenu from './link-menu.vue'

@Component({
  components: {
    LinkMenu,
    OrganizationUpgradeMenu
  },
  name: 'CodeLinkAdd'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly modelObject!: ModelObject

  @Ref() readonly menuRef?: LinkMenu

  visible = false

  get currentLandscapeId () {
    return this.$params.landcapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentOrganizationLimitLinksReached () {
    return Object.values(this.modelModule.objects).reduce((p, c) => p + Object.keys(c.links).length, 0) >= this.currentOrganizationLimits.links
  }

  get currentOrganizationLimitLinksPerItemReached () {
    return !!this.modelObject && Object.keys(this.modelObject.links).length >= this.currentOrganizationLimits.linksPerItem
  }

  open () {
    this.menuRef?.menuRef?.open()
  }

  close () {
    this.menuRef?.menuRef?.close()
  }
}
