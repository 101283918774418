import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

/**
 * Menus
 */

export const codeLinkSelectMenu = new analytics.Event({
  displayName: 'Code link select menu',
  name: 'code_link_select_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})

/**
 * Hidden
 */

export const codeLinkOpenHidden = new analytics.Event({
  displayName: 'Code link open',
  hidden: true,
  name: 'code_link_open'
}, {
  landscapeId,
  organizationId
})
