
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { SupportModule } from '@/modules/support/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  name: 'CatalogTechnologyRequest'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  supportModule = getModule(SupportModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly searchTerm!: string

  get currentLandscapeId () {
    return this.$params.landcapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape?.organizationId)
  }

  formController = new FormController({
    initialModel: {
      docsUrl: '',
      technology: '',
      updatesUrl: '',
      websiteUrl: ''
    },
    validationRules: {
      docsUrl: validationRules.url,
      technology: validationRules.exists,
      updatesUrl: validationRules.url,
      websiteUrl: validationRules.url
    }
  })

  @Watch('searchTerm')
  onSearchTermChanged () {
    if (this.formController.success) {
      this.formController.resetModel()
      this.formController.resetStatus()
    }
  }

  created () {
    this.formController.submitHandler = async model => {
      if (this.userModule.user) {
        await this.supportModule.supportMessageCreate({
          body: {
            docsUrl: model.docsUrl,
            searchTerm: this.searchTerm,
            technologyName: model.technology,
            type: 'technology-request',
            updatesUrl: model.updatesUrl,
            websiteUrl: model.websiteUrl
          },
          email: this.userModule.user.email,
          landscapeId: this.currentLandscape?.id,
          name: this.userModule.user.name,
          organizationId: this.currentOrganization?.id,
          url: window.location.href,
          userId: this.userModule.user.id
        })
      }
    }
  }
}
