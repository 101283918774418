
import { Easing, Tween } from '@tweenjs/tween.js'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'Animation'
})
export default class extends Vue {
  @Prop() readonly initialValue?: number
  @Prop({ default: 0 }) readonly value!: number
  @Prop({ default: 300 }) readonly duration!: number
  @Prop({ default: 0 }) readonly delay!: number

  animation: Tween<{ value: number }> | null = null

  currentValue = 0
  prevValue = 0
  prevPosValue = 0

  @Watch('value')
  onValueChanged (value: number) {
    this.animate(value)
  }

  created () {
    if (typeof this.initialValue === 'number') {
      this.prevValue = this.initialValue
      this.prevPosValue = this.initialValue
      this.currentValue = this.initialValue
    } else {
      this.prevValue = this.value
      this.prevPosValue = this.value
      this.currentValue = this.value
    }
  }

  mounted () {
    if (typeof this.initialValue === 'number') {
      this.animate(this.value)
    }

    this.$emit('update', this.currentValue)
  }

  destroyed () {
    this.animation?.stop()
  }

  animate (value: number) {
    if (this.animation) {
      this.animation.stop()
      this.animation = null
    }

    this.prevValue = this.value
    if (this.value > 0) {
      this.prevPosValue = this.value
    }

    this.animation = new Tween({
      value: this.currentValue
    })
      .to({
        value
      })
      .easing(Easing.Quartic.InOut)
      .delay(this.delay)
      .duration(this.duration)
      .onStart(o => {
        this.$emit('start', o.value)
      })
      .onUpdate(o => {
        this.currentValue = o.value
        this.$emit('update', o.value)
      })
      .onComplete(o => {
        this.animation = null
        this.$emit('complete', o.value)
      })
      .start()
  }
}
