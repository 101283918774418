
import { CatalogTechnology, ModelConnection, ModelConnectionPartial, ModelObject, ModelObjectIconNullable, ModelObjectPartial, ModelObjectTechnology, ModelObjectType, PermissionType, Task, TaskModelConnectionUpdate, TaskModelObjectUpdate } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import * as sort from '@/helpers/sort'
import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import * as modelAnalytics from '@/modules/model/helpers/analytics'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { CatalogModule } from '../../store'
import Request from './request.vue'

@Component({
  components: {
    Menu,
    Request
  },
  name: 'CatalogTechnologyMenu'
})
export default class extends Vue {
  catalogModule = getModule(CatalogModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly model!: ModelObject | ModelConnection | (ModelObject | ModelConnection)[]
  @Prop() readonly permission!: PermissionType
  @Prop() readonly technologyId?: string
  @Prop({ default: '2' }) readonly nudgeLeft!: string

  @Ref() readonly menuRef!: Menu

  loading = false
  visible = false
  request = false

  searchTerm = ''
  searchTermModel = ''
  searchFocused = false
  technologyCount: Record<string, number> | undefined

  get currentLandscapeId () {
    return this.$params.landcapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get object () {
    return this.model instanceof Array ? undefined : this.model
  }

  get objects () {
    return this.model instanceof Array ? this.model : this.model ? [this.model] : []
  }

  get objectIcon () {
    return this.object ? 'icon' in this.object ? this.object.icon : undefined : undefined
  }

  get objectIconUrl () {
    return this.objectIcon ? iconUrlForTheme(this.objectIcon) : undefined
  }

  get hasIcon () {
    return this.object ? 'icon' in this.object : false
  }

  get sortedTechnologies () {
    return Object
      .values(this.catalogModule.technologies)
      .filter(o => o.isGroup || o.isSystem || o.isApp || o.isStore || o.isComponent || o.isConnection)
      .sort((a, b) => {
        const aCount: number | undefined = this.technologyCount?.[a.id]
        const bCount: number | undefined = this.technologyCount?.[b.id]
        if (aCount !== undefined && bCount !== undefined && aCount === bCount) {
          return a.name.localeCompare(b.name)
        } else if (aCount !== undefined && bCount !== undefined) {
          return bCount - aCount
        } else if (aCount !== undefined && bCount === undefined) {
          return -1
        } else if (aCount === undefined && bCount !== undefined) {
          return 1
        } else {
          return a.name.localeCompare(b.name)
        }
      })
  }

  get filteredTechnologiesFuzzy () {
    return new Fuse(this.sortedTechnologies, {
      keys: [
        'name',
        'nameShort',
        'provider'
      ],
      threshold: 0.3
    })
  }

  get filteredTechnologies () {
    return this.searchTerm ? this.filteredTechnologiesFuzzy.search(this.searchTerm).map(o => o.item) : this.sortedTechnologies
  }

  get mappedTechnologies () {
    return this.filteredTechnologies.map(o => {
      const allowed: (ModelObjectType | 'connection')[] = []
      if (o.isGroup) {
        allowed.push('group')
      }
      if (o.isSystem) {
        allowed.push('system')
      }
      if (o.isApp) {
        allowed.push('app')
      }
      if (o.isStore) {
        allowed.push('store')
      }
      if (o.isComponent) {
        allowed.push('component')
      }
      if (o.isConnection) {
        allowed.push('connection')
      }

      let disabledMessage: string | null = null

      const disallowedObject = this.objects.find(o => !allowed.includes('type' in o ? o.type : 'connection'))
      const disallowedObjectType = disallowedObject ? 'type' in disallowedObject ? disallowedObject.type : 'connection' : undefined
      if (disallowedObjectType) {
        disabledMessage = `${o.nameShort || o.name} cannot be added to ${disallowedObjectType}s (only ${allowed.map(o => `${o}s`).join('/')})`
      }

      const assigned = this.objects.every(t => t.technologies[o.id])
      const assignedSome = !assigned && this.objects.some(t => t.technologies[o.id])
      const assignedIcon = this.objectIcon ? this.objectIcon?.catalogTechnologyId === o.id : undefined
      const assignedIconAll = this.objects.every(t => 'icon' in t && t.icon?.catalogTechnologyId === o.id)
      const assignedIconOnly = this.objectIcon ? this.objectIcon?.catalogTechnologyId === o.id && !assigned : undefined

      return {
        assigned,
        assignedIcon,
        assignedIconAll,
        assignedIconOnly,
        assignedSome,
        background: false,
        click: () => {
          if (!assigned || assignedSome) {
            this.addTechnology(o)
          } else {
            this.removeTechnology(o)
          }
        },
        disabledMessage,
        icon: iconUrlForTheme(o),
        technology: o
      }
    })
  }

  get structuredTechnologies () {
    const items: (this['mappedTechnologies'][0] | { heading: string, background?: boolean })[] = []

    const assignedIconOnly = this.mappedTechnologies.find(o => o.assignedIconOnly)
    const assignedTechnologies = this.mappedTechnologies.filter(o => o.assigned || o.assignedSome)
    const all = this.mappedTechnologies.filter(o => !o.disabledMessage && !o.assigned && !o.assignedSome && !o.assignedIcon && !o.assignedIconOnly)
    const notAllowed = this.mappedTechnologies.filter(o => !!o.disabledMessage && !o.assigned && !o.assignedSome && !o.assignedIcon && !o.assignedIconOnly)

    if (assignedIconOnly && this.object) {
      assignedIconOnly.background = true
      items.push({ background: true, heading: 'Assigned icon' }, assignedIconOnly)
    }

    if (assignedTechnologies.length) {
      assignedTechnologies.forEach(o => {
        o.background = true
      })
      items.push({ background: true, heading: `Assigned technologies (${assignedTechnologies.length})` }, ...assignedTechnologies)
    } else {
      items.push({ heading: 'No technologies assigned' })
    }

    if (all.length || this.searchTerm) {
      items.push({ heading: 'All' }, ...all)
    } else {
      items.push({ heading: 'No technologies' })
    }

    if (notAllowed.length) {
      items.push({ heading: 'Not allowed' }, ...notAllowed)
    }

    return items
  }

  setSearchTermDebounce = debounce(this.setSearchTerm.bind(this), 300)

  setSearchTerm (term: string) {
    this.searchTerm = term
  }

  async open () {
    this.technologyCount = this.modelModule.technologyCount

    this.visible = true

    this.searchTerm = ''
    this.searchTermModel = ''

    if (!this.catalogModule.technologiesFetched) {
      this.loading = true
      await this.catalogModule.catalogTechnologiesList({})
      this.loading = false
    }
  }

  opened () {
    analytics.catalogTechnologyMenu.track(this, {
      landscapeId: [this.currentLandscape.id],
      modelTypes: this.objects.map(o => 'type' in o ? o.type : 'connection'),
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  closed () {
    this.visible = false
  }

  setIcon (catalogTechnology: CatalogTechnology | null) {
    let icon: ModelObjectIconNullable
    if (catalogTechnology) {
      icon = {
        catalogTechnologyId: catalogTechnology.id,
        name: catalogTechnology.name,
        url: catalogTechnology.iconUrl,
        urlDark: catalogTechnology.iconUrlDark,
        urlLight: catalogTechnology.iconUrlLight
      }
    } else {
      icon = null
    }

    const revertTasks: Task[] = []
    const tasks: Task[] = []

    const func: (() => Promise<any>)[] = []

    if (this.currentDiagram?.status === 'draft') {
      const technologyUpdates = this.objects
        .filter((o): o is ModelObject => 'type' in o && !isEqual(o.icon, icon))
        .map((o): TaskModelObjectUpdate => ({
          id: o.id,
          props: {
            icon
          },
          type: 'model-object-update'
        }))

      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          tasksProposed: {
            $append: technologyUpdates.map(o => ({
              id: o.id,
              props: {
                icon: this.modelModule.objects[o.id].icon
              },
              type: o.type
            }))
          }
        },
        type: 'diagram-content-update'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        tasksProposed: {
          $append: technologyUpdates
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)

      func.push(() => this.diagramModule.diagramContentUpdate({
        diagramId: diagramContent.id,
        landscapeId: this.currentLandscape.id,
        props: diagramContentUpdate,
        versionId: this.currentVersion.id
      }))

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      this.objects
        .filter((o): o is ModelObject => 'type' in o)
        .forEach(o => {
          const prevObject = window.structuredClone(o)

          const update: Omit<ModelObjectPartial, 'commit'> = {
            icon
          }

          revertTasks.push({
            id: o.id,
            props: {
              icon: o.icon
            },
            type: 'model-object-update'
          })

          const { object, objectUpdate } = this.modelModule.generateObjectCommit(o.id, update)
          this.modelModule.setObjectVersion(object)

          func.push(() => this.modelModule.objectUpdate({
            landscapeId: this.currentLandscape.id,
            objectId: o.id,
            props: objectUpdate,
            versionId: this.currentVersion.id
          }))

          tasks.push({
            id: o.id,
            props: objectUpdate,
            type: 'model-object-update'
          })

          modelAnalytics.modelObjectUpdate.track(this, {
            landscapeId: [this.currentLandscape.id],
            modelObjectDescriptionLength: prevObject.description?.length || 0,
            modelObjectDiagramCount: Object.keys(prevObject.diagrams).length,
            modelObjectExternal: prevObject.external,
            modelObjectIconName: prevObject.icon?.name || null,
            modelObjectLinkCount: Object.keys(prevObject.links).length,
            modelObjectNameLength: prevObject.name.length,
            modelObjectParent: prevObject.parentId,
            modelObjectStatus: prevObject.status,
            modelObjectTagCount: prevObject.tagIds.length,
            modelObjectTeamOnlyEditing: prevObject.teamOnlyEditing,
            modelObjectTechnologyCount: Object.keys(prevObject.technologies).length,
            modelObjectTechnologyNames: Object.values(prevObject.technologies).map(o => o.name),
            modelObjectType: prevObject.type,
            modelObjectUpdateIconName: objectUpdate.icon?.name || null,
            organizationId: [this.currentLandscape.organizationId]
          })
        })
    }

    this.editorModule.addToTaskQueue({ func })

    this.editorModule.addTaskList({
      revertTasks: [
        ...revertTasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ],
      tasks: [
        ...tasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ]
    })
  }

  addTechnology (technology: CatalogTechnology) {
    const revertTasks: Task[] = []
    const tasks: Task[] = []

    const func: (() => Promise<any>)[] = []

    if (this.currentDiagram?.status === 'draft') {
      const technologyUpdates = [
        ...this.objects
          .filter((o): o is ModelObject => 'type' in o)
          .map((o): TaskModelObjectUpdate => {
            const nextTechnologyIndex = Object
              .values(o.technologies)
              .reduce((p, c) => c.index > p ? c.index : p, -1) + 1

            const update: Omit<ModelObjectPartial, 'commit'> = {
              technologies: {
                ...o.technologies,
                [technology.id]: {
                  color: technology.color,
                  iconUrl: technology.iconUrl,
                  iconUrlDark: technology.iconUrlDark,
                  iconUrlLight: technology.iconUrlLight,
                  id: technology.id,
                  index: nextTechnologyIndex,
                  name: technology.name,
                  nameShort: technology.nameShort,
                  provider: technology.provider,
                  type: technology.type
                }
              }
            }

            if (!o.icon && (technology.iconUrl || technology.iconUrlDark || technology.iconUrlLight)) {
              update.icon = {
                catalogTechnologyId: technology.id,
                name: technology.name,
                url: technology.iconUrl,
                urlDark: technology.iconUrlDark,
                urlLight: technology.iconUrlLight
              }
            }

            const systemExistsWithTechnologyName = Object.values(this.modelModule.objects).some(o => o.type === 'system' && o.name === technology.name)
            if (!o.icon && !o.name && o.type === 'system' && !systemExistsWithTechnologyName) {
              update.name = technology.name
              update.external = true
            }

            return {
              id: o.id,
              props: update,
              type: 'model-object-update'
            }
          }),
        ...this.objects
          .filter((o): o is ModelConnection => !('type' in o))
          .map((o): TaskModelConnectionUpdate => {
            const nextTechnologyIndex = Object
              .values(o.technologies)
              .reduce((p, c) => c.index > p ? c.index : p, -1) + 1

            const update: Omit<ModelConnectionPartial, 'commit'> = {
              technologies: {
                ...o.technologies,
                [technology.id]: {
                  color: technology.color,
                  iconUrl: technology.iconUrl,
                  iconUrlDark: technology.iconUrlDark,
                  iconUrlLight: technology.iconUrlLight,
                  id: technology.id,
                  index: nextTechnologyIndex,
                  name: technology.name,
                  nameShort: technology.nameShort,
                  provider: technology.provider,
                  type: technology.type
                }
              }
            }

            return {
              id: o.id,
              props: update,
              type: 'model-connection-update'
            }
          })
      ]

      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          tasksProposed: {
            $append: technologyUpdates.map(o => ({
              id: o.id,
              props: {
                technologies: this.modelModule.objects[o.id]?.technologies || this.modelModule.connections[o.id]?.technologies
              },
              type: o.type
            }))
          }
        },
        type: 'diagram-content-update'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        tasksProposed: {
          $append: technologyUpdates
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)

      func.push(() => this.diagramModule.diagramContentUpdate({
        diagramId: diagramContent.id,
        landscapeId: this.currentLandscape.id,
        props: diagramContentUpdate,
        versionId: this.currentVersion.id
      }))

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      this.objects
        .filter((o): o is ModelObject => 'type' in o)
        .forEach(o => {
          const prevObject = window.structuredClone(o)

          const nextTechnologyIndex = Object
            .values(o.technologies)
            .reduce((p, c) => c.index > p ? c.index : p, -1) + 1

          const update: Omit<ModelObjectPartial, 'commit'> = {
            technologies: {
              ...o.technologies,
              [technology.id]: {
                color: technology.color,
                iconUrl: technology.iconUrl,
                iconUrlDark: technology.iconUrlDark,
                iconUrlLight: technology.iconUrlLight,
                id: technology.id,
                index: nextTechnologyIndex,
                name: technology.name,
                nameShort: technology.nameShort,
                provider: technology.provider,
                type: technology.type
              }
            }
          }

          if (!o.icon && (technology.iconUrl || technology.iconUrlDark || technology.iconUrlLight)) {
            update.icon = {
              catalogTechnologyId: technology.id,
              name: technology.name,
              url: technology.iconUrl,
              urlDark: technology.iconUrlDark,
              urlLight: technology.iconUrlLight
            }
          }

          const systemExistsWithTechnologyName = Object.values(this.modelModule.objects).some(o => o.type === 'system' && o.name === technology.name)
          if (!o.icon && !o.name && o.type === 'system' && !systemExistsWithTechnologyName) {
            update.name = technology.name
            update.external = true
          }

          revertTasks.push({
            id: o.id,
            props: {
              icon: o.icon,
              technologies: o.technologies
            },
            type: 'model-object-update'
          })

          const { object, objectUpdate } = this.modelModule.generateObjectCommit(o.id, update)
          this.modelModule.setObjectVersion(object)

          func.push(() => this.modelModule.objectUpdate({
            landscapeId: this.currentLandscape.id,
            objectId: o.id,
            props: objectUpdate,
            versionId: this.currentVersion.id
          }))

          tasks.push({
            id: o.id,
            props: objectUpdate,
            type: 'model-object-update'
          })

          modelAnalytics.modelObjectUpdate.track(this, {
            landscapeId: [this.currentLandscape.id],
            modelObjectDescriptionLength: prevObject.description?.length || 0,
            modelObjectDiagramCount: Object.keys(prevObject.diagrams).length,
            modelObjectExternal: prevObject.external,
            modelObjectIconName: prevObject.icon?.name || null,
            modelObjectLinkCount: Object.keys(prevObject.links).length,
            modelObjectNameLength: prevObject.name.length,
            modelObjectParent: prevObject.parentId,
            modelObjectStatus: prevObject.status,
            modelObjectTagCount: prevObject.tagIds.length,
            modelObjectTeamOnlyEditing: prevObject.teamOnlyEditing,
            modelObjectTechnologyCount: Object.keys(prevObject.technologies).length,
            modelObjectTechnologyNames: Object.values(prevObject.technologies).map(o => o.name),
            modelObjectType: prevObject.type,
            modelObjectUpdateTechnologyCount: objectUpdate.technologies ? Object.keys(objectUpdate.technologies).length : undefined,
            modelObjectUpdateTechnologyNames: objectUpdate.technologies ? Object.values(objectUpdate.technologies).map(o => o.name) : undefined,
            organizationId: [this.currentLandscape.organizationId]
          })
        })

      this.objects
        .filter((o): o is ModelConnection => !('type' in o))
        .forEach(o => {
          const prevConnection = window.structuredClone(o)

          const nextTechnologyIndex = Object
            .values(o.technologies)
            .reduce((p, c) => c.index > p ? c.index : p, -1) + 1

          const update: Omit<ModelConnectionPartial, 'commit'> = {
            technologies: {
              ...o.technologies,
              [technology.id]: {
                color: technology.color,
                iconUrl: technology.iconUrl,
                iconUrlDark: technology.iconUrlDark,
                iconUrlLight: technology.iconUrlLight,
                id: technology.id,
                index: nextTechnologyIndex,
                name: technology.name,
                nameShort: technology.nameShort,
                provider: technology.provider,
                type: technology.type
              }
            }
          }

          revertTasks.push({
            id: o.id,
            props: {
              technologies: o.technologies
            },
            type: 'model-connection-update'
          })

          const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(o.id, update)
          this.modelModule.setConnectionVersion(connection)

          func.push(() => this.modelModule.connectionUpdate({
            connectionId: o.id,
            landscapeId: this.currentLandscape.id,
            props: connectionUpdate,
            versionId: this.currentVersion.id
          }))

          tasks.push({
            id: o.id,
            props: connectionUpdate,
            type: 'model-connection-update'
          })

          modelAnalytics.modelConnectionUpdate.track(this, {
            landscapeId: [this.currentLandscape.id],
            modelConnectionDescriptionLength: prevConnection.description?.length || 0,
            modelConnectionDirection: prevConnection.direction,
            modelConnectionNameLength: prevConnection.name.length,
            modelConnectionStatus: prevConnection.status,
            modelConnectionTechnologyCount: Object.keys(prevConnection.technologies).length,
            modelConnectionTechnologyNames: Object.values(prevConnection.technologies).map(o => o.name),
            modelConnectionUpdateDescriptionLength: connectionUpdate.description ? connectionUpdate.description.length : undefined,
            modelConnectionUpdateTechnologyCount: connectionUpdate.technologies ? Object.keys(connectionUpdate.technologies).length : undefined,
            modelConnectionUpdateTechnologyNames: connectionUpdate.technologies ? Object.values(connectionUpdate.technologies).map(o => o.name) : undefined,
            organizationId: [this.currentLandscape.organizationId]
          })
        })
    }

    this.editorModule.addToTaskQueue({ func })

    this.editorModule.addTaskList({
      revertTasks: [
        ...revertTasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ],
      tasks: [
        ...tasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ]
    })
  }

  removeTechnology (technology: CatalogTechnology) {
    const revertTasks: Task[] = []
    const tasks: Task[] = []

    const func: (() => Promise<any>)[] = []

    if (this.currentDiagram?.status === 'draft') {
      const technologyUpdates = [
        ...this.objects
          .filter((o): o is ModelObject => 'type' in o)
          .map((o): TaskModelObjectUpdate => {
            const prevObject = window.structuredClone(o)

            const update: Omit<ModelObjectPartial, 'commit'> = {}

            update.technologies = Object
              .values(prevObject.technologies)
              .filter(o => o.id !== technology.id)
              .sort(sort.index)
              .reduce<Record<string, ModelObjectTechnology>>((p, c, i) => ({
                ...p,
                [c.id]: {
                  ...c,
                  index: i
                }
              }), {})

            if (o.icon?.catalogTechnologyId === technology.id) {
              update.icon = null
            }

            if (o.name === technology.name) {
              update.name = ''
              update.external = false
            }

            return {
              id: o.id,
              props: update,
              type: 'model-object-update'
            }
          }),
        ...this.objects
          .filter((o): o is ModelConnection => !('type' in o))
          .map((o): TaskModelConnectionUpdate => {
            const prevConnection = window.structuredClone(o)

            const update: Omit<ModelConnectionPartial, 'commit'> = {}

            update.technologies = Object
              .values(prevConnection.technologies)
              .filter(o => o.id !== technology.id)
              .sort(sort.index)
              .reduce<Record<string, ModelObjectTechnology>>((p, c, i) => ({
                ...p,
                [c.id]: {
                  ...c,
                  index: i
                }
              }), {})

            return {
              id: o.id,
              props: update,
              type: 'model-connection-update'
            }
          })
      ]

      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          tasksProposed: {
            $append: technologyUpdates.map(o => ({
              id: o.id,
              props: {
                technologies: this.modelModule.objects[o.id]?.technologies || this.modelModule.connections[o.id]?.technologies
              },
              type: o.type
            }))
          }
        },
        type: 'diagram-content-update'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        tasksProposed: {
          $append: technologyUpdates
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)

      func.push(() => this.diagramModule.diagramContentUpdate({
        diagramId: diagramContent.id,
        landscapeId: this.currentLandscape.id,
        props: diagramContentUpdate,
        versionId: this.currentVersion.id
      }))

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      this.objects
        .filter((o): o is ModelObject => 'type' in o)
        .forEach(o => {
          const prevObject = window.structuredClone(o)

          const update: Omit<ModelObjectPartial, 'commit'> = {}

          update.technologies = Object
            .values(prevObject.technologies)
            .filter(o => o.id !== technology.id)
            .sort(sort.index)
            .reduce<Record<string, ModelObjectTechnology>>((p, c, i) => ({
              ...p,
              [c.id]: {
                ...c,
                index: i
              }
            }), {})

          if (o.icon?.catalogTechnologyId === technology.id) {
            update.icon = null
          }

          if (o.name === technology.name) {
            update.name = ''
            update.external = false
          }

          revertTasks.push({
            id: o.id,
            props: {
              icon: o.icon,
              technologies: o.technologies
            },
            type: 'model-object-update'
          })

          const { object, objectUpdate } = this.modelModule.generateObjectCommit(o.id, update)
          this.modelModule.setObjectVersion(object)

          func.push(() => this.modelModule.objectUpdate({
            landscapeId: this.currentLandscape.id,
            objectId: o.id,
            props: objectUpdate,
            versionId: this.currentVersion.id
          }))

          tasks.push({
            id: o.id,
            props: objectUpdate,
            type: 'model-object-update'
          })

          modelAnalytics.modelObjectUpdate.track(this, {
            landscapeId: [this.currentLandscape.id],
            modelObjectDescriptionLength: prevObject.description?.length || 0,
            modelObjectDiagramCount: Object.keys(prevObject.diagrams).length,
            modelObjectExternal: prevObject.external,
            modelObjectIconName: prevObject.icon?.name || null,
            modelObjectLinkCount: Object.keys(prevObject.links).length,
            modelObjectNameLength: prevObject.name.length,
            modelObjectParent: prevObject.parentId,
            modelObjectStatus: prevObject.status,
            modelObjectTagCount: prevObject.tagIds.length,
            modelObjectTeamOnlyEditing: prevObject.teamOnlyEditing,
            modelObjectTechnologyCount: Object.keys(prevObject.technologies).length,
            modelObjectTechnologyNames: Object.values(prevObject.technologies).map(o => o.name),
            modelObjectType: prevObject.type,
            modelObjectUpdateTechnologyCount: objectUpdate.technologies ? Object.keys(objectUpdate.technologies).length : undefined,
            modelObjectUpdateTechnologyNames: objectUpdate.technologies ? Object.values(objectUpdate.technologies).map(o => o.name) : undefined,
            organizationId: [this.currentLandscape.organizationId]
          })
        })

      this.objects
        .filter((o): o is ModelConnection => !('type' in o))
        .forEach(o => {
          const prevConnection = window.structuredClone(o)

          const update: Omit<ModelConnectionPartial, 'commit'> = {}

          update.technologies = Object
            .values(prevConnection.technologies)
            .filter(o => o.id !== technology.id)
            .sort(sort.index)
            .reduce<Record<string, ModelObjectTechnology>>((p, c, i) => ({
              ...p,
              [c.id]: {
                ...c,
                index: i
              }
            }), {})

          revertTasks.push({
            id: o.id,
            props: {
              technologies: o.technologies
            },
            type: 'model-connection-update'
          })

          const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(o.id, update)
          this.modelModule.setConnectionVersion(connection)

          func.push(() => this.modelModule.connectionUpdate({
            connectionId: o.id,
            landscapeId: this.currentLandscape.id,
            props: connectionUpdate,
            versionId: this.currentVersion.id
          }))

          tasks.push({
            id: o.id,
            props: connectionUpdate,
            type: 'model-connection-update'
          })

          modelAnalytics.modelConnectionUpdate.track(this, {
            landscapeId: [this.currentLandscape.id],
            modelConnectionDescriptionLength: prevConnection.description?.length || 0,
            modelConnectionDirection: prevConnection.direction,
            modelConnectionNameLength: prevConnection.name.length,
            modelConnectionStatus: prevConnection.status,
            modelConnectionTechnologyCount: Object.keys(prevConnection.technologies).length,
            modelConnectionTechnologyNames: Object.values(prevConnection.technologies).map(o => o.name),
            modelConnectionUpdateTechnologyCount: connectionUpdate.technologies ? Object.keys(connectionUpdate.technologies).length : undefined,
            modelConnectionUpdateTechnologyNames: connectionUpdate.technologies ? Object.values(connectionUpdate.technologies).map(o => o.name) : undefined,
            organizationId: [this.currentLandscape.organizationId]
          })
        })
    }

    this.editorModule.addToTaskQueue({ func })

    this.editorModule.addTaskList({
      revertTasks: [
        ...revertTasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ],
      tasks: [
        ...tasks,
        {
          route: this.$route,
          type: 'navigation'
        }
      ]
    })
  }
}
