import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { modelTypes } from '@/modules/model/helpers/analytics-properties'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

import { catalogTechnologyColor, catalogTechnologyName, catalogTechnologyNameShort, catalogTechnologyProvider } from './analytics-properties'

/**
 * Menus
 */

export const catalogTechnologyMenu = new analytics.Event({
  displayName: 'Catalog technology menu',
  name: 'catalog_technology_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  modelTypes,
  organizationId
})
export const catalogTechnologyFilterMenu = new analytics.Event({
  displayName: 'Catalog technology filter menu',
  name: 'catalog_technology_filter_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})

/**
 * Events
 */

export const catalogTechnologyHide = new analytics.Event({
  displayName: 'Catalog technology hide',
  name: 'catalog_technology_hide'
}, {
  catalogTechnologyColor,
  catalogTechnologyName,
  catalogTechnologyNameShort,
  catalogTechnologyProvider,
  landscapeId,
  organizationId
})
export const catalogTechnologyUnhide = new analytics.Event({
  displayName: 'Catalog technology unhide',
  name: 'catalog_technology_unhide'
}, {
  catalogTechnologyColor,
  catalogTechnologyName,
  catalogTechnologyNameShort,
  catalogTechnologyProvider,
  landscapeId,
  organizationId
})
export const catalogTechnologyFocus = new analytics.Event({
  displayName: 'Catalog technology focus',
  name: 'catalog_technology_focus'
}, {
  catalogTechnologyColor,
  catalogTechnologyName,
  catalogTechnologyNameShort,
  catalogTechnologyProvider,
  landscapeId,
  organizationId
})
export const catalogTechnologyUnfocus = new analytics.Event({
  displayName: 'Catalog technology unfocus',
  name: 'catalog_technology_unfocus'
}, {
  catalogTechnologyColor,
  catalogTechnologyName,
  catalogTechnologyNameShort,
  catalogTechnologyProvider,
  landscapeId,
  organizationId
})
export const catalogTechnologyPin = new analytics.Event({
  displayName: 'Catalog technology pin',
  name: 'catalog_technology_pin'
}, {
  catalogTechnologyColor,
  catalogTechnologyName,
  catalogTechnologyNameShort,
  catalogTechnologyProvider,
  landscapeId,
  organizationId
})
export const catalogTechnologyUnpin = new analytics.Event({
  displayName: 'Catalog technology unpin',
  name: 'catalog_technology_unpin'
}, {
  catalogTechnologyColor,
  catalogTechnologyName,
  catalogTechnologyNameShort,
  catalogTechnologyProvider,
  landscapeId,
  organizationId
})
export const catalogTechnologyPinAll = new analytics.Event({
  displayName: 'Catalog technology pin all',
  name: 'catalog_technology_pin_all'
}, {
  landscapeId,
  organizationId
})
export const catalogTechnologyUnpinAll = new analytics.Event({
  displayName: 'Catalog technology unpin all',
  name: 'catalog_technology_unpin_all'
}, {
  landscapeId,
  organizationId
})
export const catalogTechnologyFilterInclude = new analytics.Event({
  displayName: 'Catalog technology filter include',
  name: 'catalog_technology_filter_include'
}, {
  catalogTechnologyColor,
  catalogTechnologyName,
  catalogTechnologyNameShort,
  catalogTechnologyProvider,
  landscapeId,
  organizationId
})
export const catalogTechnologyFilterExclude = new analytics.Event({
  displayName: 'Catalog technology filter exclude',
  name: 'catalog_technology_filter_exclude'
}, {
  catalogTechnologyColor,
  catalogTechnologyName,
  catalogTechnologyNameShort,
  catalogTechnologyProvider,
  landscapeId,
  organizationId
})
